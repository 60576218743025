import React, { useContext } from "react"
import Layout from "../../components/layout"
import NullIf from "../../services/NullIf"

import ThemeContext from "../../context/ThemeContext"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import ContentWrapper from "../../components/text-helpers/content-wrapper"
import MainSearch from "../../components/main-search/main-search"
import localize from "../../components/localize"
import { localizeItem } from "../../graphql-assistant/createLocaleTextGetter"

const Content = ({ bonuses, language, locale }) => {
  return (
    <div>
      <MainSearch locale={locale} bonuses={bonuses} />
    </div>
  )
}

const CasinoGroupItem = ({ pageContext, location, data }) => {
  let site = useContext(ThemeContext)
  let { language, locale, alternateLocaleSlugs } = pageContext
  let bonuses = site.bonuses

  let pageData = data.sanityLanguage
  bonuses = bonuses
    .filter(x => NullIf.isNotNullOrEmpty(x))
    .filter(x => NullIf.isNotNullOrEmpty(x.languagesOffered))
    .filter(x => x.languagesOffered.some(y => y.name === localizeItem(pageData, [locale]).name))


  if (!pageData)
    pageData = {
      seoTitle: `${language} | Mr-Gamble.com`,
      seoMeta: `${language} | Mr-Gamble.com`,
    }

  return (
    <Layout silo={data.sanitySilo} pageContext={pageContext} location={location}>
      <SEO location={location} title={pageData.seoTitle}
           alternateSlug={alternateLocaleSlugs}
           description={pageData.seoMeta} bonuses={bonuses} />
      <ContentWrapper
        title={pageData.h1Tag}
        header={pageData._rawHeaderText}
        footer={pageData.footerText}
        headerClassName={`gambleGeneralWrapper`}
        bodyClassName={`gambleGeneralWrapper`}
        useFullBody={true}
      >
        <Content bonuses={bonuses} locale={locale} language={language} />
      </ContentWrapper>
    </Layout>
  )
}

export default localize(CasinoGroupItem)
export const pageQuery = graphql`
  query CasinoLanguageQuery($language: String!) {
      sanitySilo (_id: {eq: "c2f8b64b-b695-44fd-beb1-daca06999131"}){
          _id
          breadcrumbTrail {
              ...LocaleSiloTrails
          }
      }
      sanityLanguage(_id: {eq: $language}) {
      name {
          ...LocaleString
      }
      seoTitle {
          ...LocaleString
      }
      _rawHeaderText(resolveReferences: {maxDepth: 10})
    }
  }
`
